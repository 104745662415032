import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/core/components/ui/Button.vue';
import { AUTH_TOKEN } from '@/core/constants';
import { getLocalStorageWithExpiry } from '@/core/utils/common';
// import { DASHBOARD_ROUTE } from '@/modules/dashboard/routes';
import { ATTACHMENT_CACHE_STORE } from '@/store/modules/attachment-cache';
import { USER_PUBLIC_PROFILE_CACHE } from '@/store/modules/attachment-cache/constants';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    components: {
        Button
    },
    emits: ['next'],
    data() {
        return {
            publicProfile: {
                profileImage: ''
            },
            rules: {
                profileImage: [
                    { required: true, message: 'Add a profile image.', trigger: 'change' },
                ],
            },
            apiUrl: process.env.VUE_APP_API_URL,
            profileImage: null,
            uploadingProfileImage: false,
            newUploadedImageKey: '',
            isProfileImageLoaded: false,
            isSubmitting: false,
            hasSavedProfilePhoto: false
        };
    },
    watch: {
        'record.profileImage': {
            deep: true,
            handler(profileImage) {
                if (profileImage) {
                    this.newUploadedImageKey = profileImage;
                    this.loadProfileImageUrl(profileImage);
                }
            }
        }
    },
    computed: {
        ...mapGetters(['isProjectOwner']),
        ...mapGetters(USERS_STORE, ['user']),
        uploadHeaders() {
            const token = getLocalStorageWithExpiry(AUTH_TOKEN);
            return {
                Authorization: `Bearer ${token}`
            };
        }
    },
    async created() {
        if (this.user?.userPublicProfile) {
            const { user } = this;
            const { userPublicProfile } = user;
            await this.loadProfileImageUrl(userPublicProfile.profileImage);
            if (this.profileImage) {
                this.hasSavedProfilePhoto = true;
            }
        }
    },
    methods: {
        ...mapActions(ATTACHMENT_CACHE_STORE, [
            'getAttachments',
            'resetCacheEntity'
        ]),
        ...mapActions(USERS_STORE, [
            'getUserProfile',
            'saveUserProfile',
            'getAccountStatuses'
        ]),
        ...mapActions(USERS_STORE, ['getUser', 'updateUser', 'saveUserState']),
        getUserPublicProfile() {
            if (this.user.userPublicProfile) {
                this.getUserProfile({ userId: this.user.id, userPublicProfileId: this.user.userPublicProfile.id })
                    .then((response) => {
                    this.publicProfile = {
                        profileImage: response.profileImage
                    };
                });
            }
        },
        loadProfileImageUrl(value) {
            this.getAttachments({
                name: USER_PUBLIC_PROFILE_CACHE,
                attachments: [{ attachment: value }],
                returnFirstOnly: true,
            })
                .then(async ({ src }) => {
                this.profileImage = src;
                this.isProfileImageLoaded = true;
                this.newUploadedImageKey = value;
            })
                .catch(() => { })
                .finally(() => {
                this.isProfileImageLoaded = true;
            });
        },
        handleErrorFiles(err) {
            this.$notify.error({
                title: 'Upload Error',
                message: err || 'Uploading files error.'
            });
        },
        onProgressUploadProfileImage() {
            this.uploadingProfileImage = true;
        },
        updateProfileImageOnSuccess(response) {
            this.profileImage = null;
            this.newUploadedImageKey = response.attachment;
            this.profileImage = response.src;
            this.uploadingProfileImage = false;
        },
        submit() {
            this.isSubmitting = true;
            const payload = {
                userId: this.user.id,
                profile: {
                    profileImage: this.newUploadedImageKey
                },
            };
            this.saveUserProfile(payload)
                .then(async () => {
                this.$notify.success({
                    type: 'success',
                    message: 'Photo successfully saved.'
                });
                // const { user } = this;
                // const roleId = user.role.id;
                // if (this.isProjectOwner) {
                //   await this.updateUser({ user: { roleId, hasAccountSetup: true }, userId: user.id });
                // }
                // this.saveUserState(await this.getUser(this.user.id));
                // // this.getUserPublicProfile();
                // // this.getAccountStatuses();
                // window.location.href = `/${DASHBOARD_ROUTE}`;
                this.$router.push({ name: 'setup/contact' });
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error submitting photo.'
                });
            })
                .finally(() => {
                this.isSubmitting = false;
            });
        },
        onNextTemporary() {
            this.$emit('next', true);
        }
    }
});
